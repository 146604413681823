<template>
  <transition name="slide-fade"
    mode="out-in">
    <router-view></router-view>
  </transition>
</template>
<script>
import { mapGetters } from 'vuex';

export default {
  computed: {
    ...mapGetters('roles', [
      'getAllRoles',
    ]),
  },
  methods: {
    avaliaAcesso() {
      if (this.getAllRoles.indexOf('LIQ_ND_PAGINA') >= 0) {
        return;
      }
      this.$router.push({ name: 'inicio' });
      this.$toast(this.$t('message.acesso_negado'));
    },
  },
  mounted() {
    window.setTimeout(() => this.avaliaAcesso(), 1E3);
  },
};
</script>
